import React from "react";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";

export interface IBlogDT {
  id: string;
  title: string;
  date: string;
  content?: string;
  img: string;
  url?: string;
  width?: number;
  height?: number;
}

export default function BlogItemTwo({ item }: { item: IBlogDT }) {
  const blogUrl = item.url || "#";

  const formattedDate = moment(item.date).isValid()
    ? moment(item.date).format("MMMM D, YYYY")
    : "Invalid Date";

  if (!blogUrl) {
    console.error(`No URL provided for blog item with id: ${item.id}`);
  }

  return (
    <div className="tp-blog-item tp_fade_bottom">
      <div className="tp-blog-thumb fix p-relative">
        <Link href={blogUrl} target="_blank" rel="noopener noreferrer">
          <div className="relative w-full" style={{ height: "400px" }}>
            <Image
              src={item.img}
              alt="blog-img"
              fill
              style={{ objectFit: 'cover' }}
              className="rounded-md"
            />
          </div>
        </Link>
        <div className="tp-blog-meta">
          <span>{formattedDate}</span>
        </div>
      </div>
      <div className="tp-blog-content">
        <h4 className="tp-blog-title-sm">
          <Link href={blogUrl} target="_blank" rel="noopener noreferrer">
            {item.title}
          </Link>
        </h4>
      </div>
    </div>
  );
}
