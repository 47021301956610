"use client";
import { gsap } from "gsap";
import React, { useEffect } from "react";
import useScrollSmooth from "@/hooks/use-scroll-smooth";
import { ScrollSmoother, SplitText } from "@/plugins";
import { useGSAP } from "@gsap/react";

// internal imports
import Wrapper from "@/layouts/wrapper";
import VideoTwo from "@/components/video/video-two";
import ServiceTwo from "@/components/service/service-two";
import AwardTwo from "@/components/award/award-two";
import InstagramArea from "@/components/instagram/instagram-area";
import FooterTwo from "@/layouts/footers/footer-two";
// animation
import {
  bounceAnimation,
  heroBgAnimation,
  heroTitleAnim,
} from "@/utils/title-animation";
import { videoAnimTwo } from "@/utils/video-anim";
import { panelOneAnimation } from "@/utils/panel-animation";
import { awardAnimOne } from "@/utils/award-anim";
import { instagramAnim } from "@/utils/instagram-anim";
import { hoverBtn } from "@/utils/hover-btn";
import HeroBannerSix from "@/components/hero-banner/hero-banner-six";
import ProjectFour from "@/components/project/project-four";
import { textInvert } from "@/utils/text-invert";
import { fadeAnimation, revelAnimationOne } from "@/utils/title-animation";
import { projectThreeAnimation } from "@/utils/project-anim";
import { ctaAnimation } from "@/utils/cta-anim";
import HeaderOne from "@/layouts/headers/header-one";
import BlogOne from "@/components/blog/blog-one";
import MissionOne from "@/components/mission/mission-one";
import LineTextThree from "@/components/line-text/line-text-3";
import TestimonialOne from "@/components/testimonial/testimonial-one";
import ServiceHome from "@/components/blog/service-home";
import BrandSlider from "@/components/brand/brand-slider";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { ToastContainer } from "react-toastify";
import doctor from "/public/assets/img/logo/logo.png";
import styled from "styled-components";

const WrapperDiv = styled.div`
  overflow-x: hidden;
  overflow: hidden;
`;

const HomeTwoMain = () => {
  useEffect(() => {
    const chatBox = document.querySelector(".floating-whatsapp-chatbox");
    const handleClickInside = (event: Event) => {
      event.stopPropagation();
    };

    if (chatBox) {
      const inputElements = chatBox.querySelectorAll("input, textarea, button");
      inputElements.forEach((element) => {
        element.addEventListener("click", handleClickInside);
      });
    }

    return () => {
      if (chatBox) {
        const inputElements = chatBox.querySelectorAll(
          "input, textarea, button"
        );
        inputElements.forEach((element) => {
          element.removeEventListener("click", handleClickInside);
        });
      }
    };
  }, []);

  return (
    <WrapperDiv>
      <FloatingWhatsApp
        phoneNumber="905439086696"
        accountName="ray dent clinic"
        statusMessage="We are available to chat!"
        chatMessage="Hello! How can we assist you today?"
        avatar={doctor.src}
        darkMode={false}
        chatboxStyle={{
          fontFamily:
            'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        }}
        allowClickAway={true}
      />
      <ToastContainer />
      <HeaderOne />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <main>
            <HeroBannerSix />
            {/* <FooterFour /> */}
            <MissionOne />
            <ServiceHome />
            <ProjectFour />
            <VideoTwo />
            <ServiceTwo />
            <AwardTwo />
            <BlogOne />
            <LineTextThree cls="tp-project-text-slide" data_2={true} />
            {/* <TestimonialOne /> */}
            <BrandSlider />
            {/* <InstagramArea /> */}
          </main>
          <FooterTwo />
        </div>
      </div>
    </WrapperDiv>
  );
};

export default HomeTwoMain;
