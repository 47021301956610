import React from "react";
import Image from "next/image";
import Link from "next/link";
import { UpArrow } from "../svg";

export default function MissionOne() {
  // images
  const m_img_1 = require("@/assets/img/home-02/mission/award.webp");
  const brand_1 = require("@/assets/img/inner-brand/image.webp");
  const brand_images = [brand_1];

  return (
    <div className="tp-mission-area pt-90  p-relative">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="tp-mission-title-box mb-80 text-center">
              <h4 className="tp-section-title-90 tp_fade_bottom p-relative tp_text_invert tp_fade_bottom">
                <span className="tp-section-title-90 tp_text_invert d-none d-lg-block tp_fade_left">
                  AWARD-WINNING DENTAL CLINIC ISTANBUL
                </span>
                Your Gateway to Aesthetic Perfection
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="tp-mission-thumb">
              <Image
                data-speed=".8"
                src={m_img_1}
                alt="thumb-img"
                style={{ height: "auto" }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="tp-about-2-content">
              <p className="tp_fade_bottom">
                We excel in medical tourism, specializing in high-quality
                aesthetic treatments. Our experienced surgeons and cutting-edge
                technology ensure safe and precise results. With over 50,000
                successful treatments, we have become a trusted choice for
                transformative care.
              </p>
              <p className="tp_fade_bottom">
                Our mission is to provide meaningful experiences that enhance
                well-being and boost self-confidence.
              </p>
              <div
                className="row gx-80 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1"
                style={{ width: "400%" }}
              >
                {brand_images.map((b, i) => (
                  <div className="col" key={i}>
                    <div className="bd-brand-item mb-80">
                      <Image
                        src={b}
                        alt="brand-img"
                        style={{ height: "auto" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="tp-hover-btn-wrapper tp_fade_bottom">
                <Link
                  className="tp-btn-circle-2 tp-hover-btn-item tp-hover-btn"
                  href="/about-us"
                >
                  <span>
                    <UpArrow />
                    <br />
                    About us
                  </span>
                  <i className="tp-btn-circle-dot"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .row {
            width: 100% !important;
          }
        }
      `}</style>
    </div>
  );
}
