"use client";
import React from "react";
import Image from "next/image";
import Marquee from "react-fast-marquee";

// brand images
import b_1 from "@/assets/img/home-02/brand/part1.png";
import b_2 from "@/assets/img/home-02/brand/part2.png";
import b_3 from "@/assets/img/home-02/brand/part3.png";
import b_4 from "@/assets/img/home-02/brand/part4.png";
import b_5 from "@/assets/img/home-02/brand/part5.png";


const brand_images = [b_1, b_2, b_3, b_4, b_5,  b_1, b_2, b_3, b_4, b_5];

export default function BrandSlider() {
  return (
    <div className="tp-brand-slider-active fix mb-100">
      <Marquee
        speed={100}
        loop={0}
        className="brand-wrapper"
      >
        {brand_images.map((b, i) => (
          <div key={i} className="tp-brand-item" style={{ height: "auto",width: "200px" }}>
            <Image src={b} alt="" />
          </div>
        ))}
      </Marquee>
    </div>
  );
}
