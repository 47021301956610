import React, { useRef } from "react";
import Image from "next/image";
import { service_data } from "@/data/service-data";
import usePagination from "@/hooks/use-pagination";
import Pagination from "../ui/pagination";
import { Service } from "@/types/service";

const truncateText = (text: string, wordCount: number) => {
  const words = text.split(" ");
  if (words.length > wordCount) {
    return words.slice(0, wordCount).join(" ") + "...";
  }
  return text;
};

const serviceItemStyle: React.CSSProperties = {
  overflow: "hidden",
  position: "relative",
};

const serviceThumbStyle: React.CSSProperties = {
  position: "relative",
  transition: "transform 0.3s ease-in-out",
};

const overlayStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1,
};

const titleContainerStyle: React.CSSProperties = {
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  color: "white",
  textAlign: "center",
  padding: "10px 0",
  transform: "translateY(0)",
  opacity: 1,
  transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  zIndex: 2,
};

const textStyle: React.CSSProperties = {
  display: "none",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
};

function ServiceItem({ item }: { item: Service }) {
  const handleMouseEnter = (e: React.MouseEvent) => {
    const text = e.currentTarget.querySelector("p") as HTMLElement;
    const titleContainer = e.currentTarget.querySelector(
      ".tp-blog-title-container"
    ) as HTMLElement;

    if (text && titleContainer) {
      text.style.display = "block";
      setTimeout(() => {
        text.style.opacity = "1";
        text.style.transform = "translateY(0)";
      }, 10);
      titleContainer.style.transform = "translateY(-20px)";
      titleContainer.style.opacity = "1";
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    const text = e.currentTarget.querySelector("p") as HTMLElement;
    const titleContainer = e.currentTarget.querySelector(
      ".tp-blog-title-container"
    ) as HTMLElement;

    if (text && titleContainer) {
      text.style.opacity = "0";
      text.style.transform = "translateY(20px)";
      titleContainer.style.transform = "translateY(0)";
      titleContainer.style.opacity = "1";
      setTimeout(() => {
        text.style.display = "none";
      }, 300);
    }
  };

  return (
    <div
      className="tp-blog-item"
      style={serviceItemStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="tp-blog-thumb fix p-relative" style={serviceThumbStyle}>
        <div style={overlayStyle}></div>
        <Image src={item.img} alt="service-img" style={{ height: "auto" }} />
        <div className="tp-blog-title-container" style={titleContainerStyle}>
          <Image src={item.icon} alt="icon" width={100} height={100} />
          <h4 className="tp-blog-title-sm">
            <a
              href={`/service/${encodeURIComponent(item.id).replace(
                /%20/g,
                "-"
              )}`}
              style={{ color: "white", textDecoration: "none" }}
            >
              {item.title}
            </a>
          </h4>
          {item.subtitle1}
          <p style={textStyle}>{truncateText(item.text3 ?? "", 15)}</p>
        </div>
      </div>
    </div>
  );
}

export default function ServiceHome() {
  const { currentItems, handlePageClick, pageCount } = usePagination<Service>(
    service_data,
    4
  );
  const servicesRef = useRef<HTMLDivElement>(null);

  const handlePageClickWithScroll = (event: { selected: number }) => {
    handlePageClick(event);

    if (servicesRef.current) {
      const offsetPosition =
        servicesRef.current.getBoundingClientRect().top + window.scrollY - 30;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="services-style-two-area bg-gray default-padding bottom-less pb-120 pt-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 mb-30">
            <div
              className="service-style-two-heading"
              style={{
                backgroundColor: "#e6b745",
                color: "white",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h5 className="sub-title">Ray Dental Clinic</h5>
              <h2 className="title fs-2 text-white">
                Founded by a team of dental specialists, Ray Dental Clinic
                offers a comprehensive range of cosmetic and therapeutic dental
                services. We partner with leading hospitals to ensure our
                patients receive complete and quality care tailored to their
                unique needs.
              </h2>
            </div>
          </div>
          {currentItems.map((item) => (
            <div key={item.id} className="col-xl-4 col-lg-6 col-md-6 mb-50">
              <ServiceItem item={item} />
            </div>
          ))}
        </div>
        <div className="col-12">
          <div className="basic-pagination mt-40 d-flex align-items-center justify-content-center">
            <nav>
              <Pagination
                handlePageClick={handlePageClickWithScroll}
                pageCount={pageCount}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
