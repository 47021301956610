"use client";
import React, { useEffect, useState } from "react";
import sanityClient from "../../../sanity/lib/client";

type VideoContent = {
  _id: string;
  title: string;
  description: string;
  video: {
    asset: {
      url: string;
    };
  };
};

const VideoTwo = () => {
  const [videoData, setVideoData] = useState<VideoContent | null>(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "videoContent"][0]{
          _id,
          title,
          description,
          video {
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => {
        setVideoData(data);
      })
      .catch(console.error);
  }, []);

  if (!videoData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tp-video-area pt-100">
      <div className="container container-1870">
        <div className="row">
          <div className="col-xl-12">
            <div className="tp-video-wrap p-relative">
              <video
                className="play-video"
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
                controls
              >
                <source src={videoData.video.asset.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTwo;
