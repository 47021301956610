import React, { useEffect, useState } from "react";
import BlogItemTwo, { IBlogDT } from "./blog-item/blog-item-2";
import axios from "axios";

export default function BlogOne() {
  const [blogItems, setBlogItems] = useState<IBlogDT[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogItems = async () => {
      try {
        const response = await axios.get(
          "https://ray-dental-clinic.onrender.com/posts"
        );
        const items = response.data.map((item: any) => ({
          id: item.guid,
          title: item.title,
          date: item.published,
          content: item.content || item.content_encoded,
          img:
            item.enclosure?.url ||
            extractFirstImage(item.content || item.content_encoded) ||
            "/default-image.jpg",
          width: item.enclosure?.width || 600,
          height: item.enclosure?.height || 400,
          url: item.link || "#",
        }));

        setBlogItems(items.slice(0, 4));
      } catch (err) {
        console.error("Failed to fetch blog items:", err);
        setError("Failed to load blog items. Please try again later.");
      }
    };

    fetchBlogItems();
  }, []);

  const extractFirstImage = (htmlContent: string): string | null => {
    const doc = new DOMParser().parseFromString(htmlContent, "text/html");
    const imgElement = doc.querySelector("img");
    return imgElement ? imgElement.src : null;
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="tp-blog-area pb-70">
      <div className="container container-1775">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <div className="tp-project-3-title-box p-relative mb-100 mt-100">
              <h4 className="tp-section-title-200">Latest Blogs</h4>
            </div>
          </div>
        </div>
        <div className="row">
          {blogItems.map((item) => (
            <div key={item.id} className="col-xl-3 col-lg-6 col-md-6 mb-50">
              <BlogItemTwo item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}