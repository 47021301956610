import React from "react";
import { Leaf } from "../svg";
import Image from "next/image";
// service icon
import ser_1 from "@/assets/img/home-02/service/sv-icon-1.png";
import ser_2 from "@/assets/img/home-02/service/sv-icon-2.png";
import ser_3 from "@/assets/img/home-02/service/sv-icon-3.png";
import ser_4 from "@/assets/img/home-02/service/sv-icon-4.png";
// shape
import shape from "@/assets/img/home-02/service/10.png";

const benefitsAccordion = [
  {
    id: 1,
    icon: ser_1,
    title: "Comprehensive Care",
    desc: "Our dental clinic offers a wide range of treatments to ensure that all your oral health needs are met under one roof.",
  },
  {
    id: 2,
    icon: ser_2,
    title: "Expert Team",
    desc: "Our experienced dental professionals are dedicated to providing personalized care and the highest quality treatments.",
  },
  {
    id: 3,
    icon: ser_3,
    title: "Advanced Technology",
    desc: "We utilize the latest dental technology to ensure precise diagnoses and effective treatment plans for our patients.",
  },
  {
    id: 4,
    icon: ser_4,
    title: "Comfort & Care",
    desc: "Your comfort is our priority. We strive to create a welcoming and relaxing environment for every visit.",
  },
];

export default function ServiceTwo() {
  return (
    <div className="tp-service-2-area tp-service-2-pt pb-100 z-index-5">
      <div className="container container-1480">
        <div className="row">
          <div className="col-xl-8">
            <div className="tp-service-2-title-box mb-70">
              <span className="tp-section-subtitle-3">
                <span></span>
              </span>
              <h4 className="tp-section-title-40">
                Experience quality dental care that prioritizes your health and
                comfort.
              </h4>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xxl-6 col-xl-4 col-lg-4">
            <div className="tp-service-2-shape-img text-lg-start">
            <Image src={shape} alt="Shape" width={190} height={190} />
            </div>
          </div>
          <div className="col-xxl-5 col-xl-8 col-lg-8">
            <div className="tp-service-2-accordion-box">
              <div className="accordion" id="accordionExample">
                {benefitsAccordion.map((b) => (
                  <div key={b.id} className="accordion-items">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-buttons ${
                          b.id !== 2 ? "collapsed" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${b.id}`}
                        aria-expanded={b.id === 2 ? "true" : "false"}
                        aria-controls={`collapse-${b.id}`}
                      >
                        <span>
                          <Image src={b.icon} alt="icon" />
                        </span>
                        {b.title}
                        <span className="accordion-icon"></span>
                      </button>
                    </h2>
                    <div
                      id={`collapse-${b.id}`}
                      className={`accordion-collapse collapse ${
                        b.id === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>{b.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
